// 1. websocket连接：判断浏览器是否兼容，获取 websocket url 并连接
// 2. 获取浏览器录音权限：判断浏览器是否兼容，获取浏览器录音权限
// 3. js获取浏览器录音数据
// 4. 将录音数据处理为文档要求的数据格式：采样率16k或8K、位长16bit、单声道；该操作属于纯数据处理，使用webWork处理
// 5. 根据要求（采用base64编码，每次发送音频间隔40ms，每次发送音频字节数1280B）将处理后的数据通过websocket传给服务器，
// 6. 实时接收 websocket 返回的数据并进行处理

import parser from 'fast-xml-parser'
import CryptoJS from 'crypto-js'
import { Base64 } from './js/base64js.js'

import { MediaManager } from './MediaManager'
import { message } from './message'
import { Logger } from './Logger'
import { Toast } from 'vant'

export const Recorder_status = {
  NULL: 'null',
  INIT: 'init',
  INIT_FAIL: 'init fail',
  INIT_FINISH: 'init finish',
  RECORDING: 'recording',
  RECORD_END: 'record end',
  RECORD_FINISH: 'record finish',
}

const WebSocket = 'WebSocket' in window ? window.WebSocket : 'MozWebSocket' in window ? window.MozWebSocket : null

export const isSupportWebSocket = !!WebSocket

const AudioContext = window.AudioContext || window.webkitAudioContext

export const isSupportAudioContext = !!AudioContext

const customParamsKeys = ['ent', 'category', 'text', 'group', 'check_type']
const extractParams = (params = {}, defaultParams = {}) => {
  return customParamsKeys.reduce((p, key) => {
    const value = params[key] || defaultParams[key]
    if (value !== undefined) {
      p[key] = value
    }
    return p
  }, {})
}

export class IseRecorder {
  status = Recorder_status.NULL

  /**
   * @class IseRecorder
   *
   * - 评测参数
   * @see https://www.xfyun.cn/doc/Ise/IseAPI.html#%E4%B8%9A%E5%8A%A1%E5%8F%82%E6%95%B0%E8%AF%B4%E6%98%8E-business
   *
   * @param {object} [options={}]
   * @param {string} options.url IseWebSocket 链接
   * @param {string} options.appId 在平台申请的 APPID 信息，在控制台-我的应用-语音评测（流式版）页面获取
   * @param {object} options.logger 信息记录工具
   *
   * @param {object} [options.params={}] 评测参数，用作未传参时的默认值，也可以调用 startRecord 进行传参覆盖
   * @param {'cn_vip'|'en_vip'} [options.params.ent='cn_vip'] 评测参数， 中文：cn_vip 英文：en_vip
   * @param {'read_syllable'|'read_word'|'read_sentence'|'read_chapter'} [options.params.category='read_sentence'] 题型
   * @param {`\uFEFF${string}`} [options.params.text=''] 待评测文本 utf8 编码，需要加 utf8bom 头
   * @param {'adult'|'youth'|'pupil'} [options.params.group='adult'] 针对群体不同，相同试卷音频评分结果不同 （仅中文字、词、句、篇章题型支持），此参数会影响准确度得分
   * @param {'easy'|'common'|'hard'} [options.params.check_type='common'] 设置评测的打分及检错松严门限（仅中文引擎支持）
   */
  constructor(options) {
    this.setOptions(options)

    if (!this.appId || this.appId === 'APPID') {
      sendErrorLogToServer(message.NOT_APPID)
      throw Error(message.NOT_APPID)
    }

    // 记录音频数据
    this.audioData = []
    this.sendedAudioData = []
    this.closeEventCode = ''
    this.lastCloseEventCode = ''
    // 记录评测结果
    this.resultText = ''

    this.iseWebSocket = null
    this.audioContext = null
    this.mediaManager = new MediaManager('audio')
    this.handlerInterval = null
  }

  resolveOptions(options) {
    this.logger = options.logger || new Logger()
    this.url = options.url
    this.appId = options.appId
    this.appSecret = options.appSecret
    this.appKey = options.appKey
  }

  setOptions(options) {
    this.options = options
    this.resolveOptions(options)
  }

  setStatus(status) {
    if (Object.values(Recorder_status).includes(status) && this.status !== status) {
      const lastStatus = this.status
      this.status = status
      this.options.onWillStatusChange?.(lastStatus, this.status)
    }
  }

  setResultText(text) {
    if (this.resultText !== text) {
      this.resultText = text
      this.options.onTextChange?.(this.resultText)
    }
  }

  handleWebSocketMessage(message) {
    this.closeEventCode += 'p'
    // 识别结束
    const jsonData = JSON.parse(message)
    this.jsonD = message
    if (jsonData.message !== 'success') {
      this.closeEventCode += `${jsonData.message}`
    }
    if (jsonData.code === 0 && jsonData.data.status === 2) {
      this.closeEventCode += 'r'
      this.iseWebSocket.close()
    }
    if (jsonData.data?.data) {
      this.closeEventCode += 'q'
      console.log('jsonData', jsonData)
      const data = Base64.decode(jsonData.data.data)
      const grade = parser.parse(data, {
        attributeNamePrefix: '',
        ignoreAttributes: false,
      })
      this.closeEventCode += 'z'
      this.setResultText(grade)
      this.options.getSID(jsonData?.sid)
    }
    if (jsonData.code !== 0) {
      this.closeEventCode += 's'
      this.iseWebSocket.close()
      this.logger.log(jsonData.message, jsonData.code)
    }
  }

  connectWebSocket() {
    this.closeEventCode += 't'
    if (!this.url) {
      this.logger.warn(message.NOT_URL)
      return
    }
    this.closeEventCode += 'u'
    const iseWebSocket = (this.iseWebSocket = new WebSocket(this.url))
    console.log('create iseWebSocket')
    iseWebSocket.onopen = () => {
      this.closeEventCode += 'c'
      console.log('iseWebSocket open')
      this.setStatus(Recorder_status.RECORDING)
      // 重新开始录音
      setTimeout(() => {
        this.closeEventCode += 'v'
        this.webSocketSend()
      }, 40)
    }
    iseWebSocket.onmessage = (e) => {
      // console.log('iseWebSocket onmessage')
      this.handleWebSocketMessage(e.data)
    }
    iseWebSocket.onerror = async (e) => {
      this.closeEventCode += 'w' + this.jsonD
      this.logger.error('iseWebSocket onerror', this.closeEventCode, this.status, e)
      sendErrorLogToServer('iseWebSocket onerror' + (e.message || ''))
      this.stopRecord()
    }
    iseWebSocket.onclose = (e) => {
      this.closeEventCode += 'x'
      this.closeEventCode += 'websocket 断开: ' + e.code + ' ' + e.reason + ' ' + e.wasClean
      // this.logger.info('onclose status:' + e.code)
      // this.logger.log('iseWebSocket onclose')
      this.stopRecord()
    }
  }

  /**
   * 获取 WebSocket url
   */
  getIseWebSocketUrl() {
    const API_SECRET = this.appSecret
    const API_KEY = this.appKey
    if (!API_SECRET || !API_KEY) {
      return this.url
    }
    const baseUrl = 'wss://ise-api.xfyun.cn/v2/open-ise'
    const host = 'ise-api.xfyun.cn'
    const date = new Date().toGMTString()
    const algorithm = 'hmac-sha256'
    const headers = 'host date request-line'
    const signatureOrigin = `host: ${host}\ndate: ${date}\nGET /v2/open-ise HTTP/1.1`
    const signatureSha = CryptoJS.HmacSHA256(signatureOrigin, API_SECRET)
    const signature = CryptoJS.enc.Base64.stringify(signatureSha)
    const authorizationOrigin = `api_key="${API_KEY}", algorithm="${algorithm}", headers="${headers}", signature="${signature}"`
    const authorization = btoa(authorizationOrigin)
    const url = `${baseUrl}?authorization=${authorization}&date=${date}&host=${host}`
    return url
  }

  recordInit() {
    this.closeEventCode += 'y'
    if (this.status === Recorder_status.INIT || this.status === Recorder_status.INIT_FINISH || this.audioContext) {
      return
    }
    if (!isSupportAudioContext) {
      this.logger.warn(message.NOT_SUPPORT_WEB_AUTIO)
      return
    }
    if (!isSupportWebSocket) {
      this.logger.warn(message.NOT_SUPPORT_WEB_SOCKET)
      return
    }

    this.closeEventCode += 'g'
    this.setStatus(Recorder_status.INIT)

    return this.mediaManager
      .getMediaPermissions()
      .then((stream) => {
        // 创建音频环境
        this.closeEventCode += 'h'
        this.audioContext = new AudioContext({
          sampleRate: 44100,
        })
        this.audioContext.resume()

        // 创建一个用于通过JavaScript直接处理音频
        const scriptProcessor = this.audioContext.createScriptProcessor(0, 1, 1)
        scriptProcessor.onaudioprocess = (e) => {
          // 去处理音频数据
          if (this.status === Recorder_status.RECORDING) {
            this.audioData.push(...transAudioData(e.inputBuffer.getChannelData(0)))
          }
        }
        // 连接
        scriptProcessor.connect(this.audioContext.destination)

        // 创建一个新的 MediaStreamAudioSourceNode 对象，使来自 MediaStream 的音频可以被播放和操作
        const mediaSource = this.audioContext.createMediaStreamSource(stream)
        // 连接
        mediaSource.connect(scriptProcessor)

        // 创建 WebSocket
        this.connectWebSocket()

        this.setStatus(Recorder_status.INIT_FINISH)
      })
      .catch((e) => {
        this.logger.warn(e)
        Toast.error('录音插件加载失败', e.message)
        sendErrorLogToServer('录音插件加载失败:' + (e.message || ''))
        this.closeEventCode += 'i'
        // 关闭 websocket
        if (this.iseWebSocket && this.iseWebSocket.readyState === 1) {
          this.closeEventCode += 'j'
          this.iseWebSocket.close()
        }
        this.setStatus(Recorder_status.INIT_FAIL)
        return e.message
        // this.logger.error('audioContext init error', this.iseWebSocket.readyState, this.status, e)
      })
  }

  async startRecord(params) {
    this.params = extractParams(params, this.options.params)
    this.lastCloseEventCode = this.closeEventCode
    this.closeEventCode = ''
    try {
      this.url = await this.getWssUrl()
    } catch (error) {
      console.log(error, 'IsRecorder.js,line:280,获取url失败')
    }
    if (!this.audioContext) {
      this.closeEventCode += 'a'
      return this.recordInit()
    } else {
      this.closeEventCode += 'b'
      this.audioContext.resume()
      this.connectWebSocket()
    }
  }

  stopRecord() {
    this.closeEventCode += 'f'
    if (this.status !== Recorder_status.RECORDING) {
      this.closeEventCode += 'e'
      // this.logger.log(this.status)
      return
    }
    // safari 下 suspend 后再次 resume 录音内容将是空白，设置 safari 下不做 suspend
    if (!/Safari/.test(navigator.userAgent)) {
      this.audioContext.suspend()
    }
    this.setStatus(Recorder_status.RECORD_END)
  }

  cropAudioData(length = 5000) {
    const startIdx = this.sendedAudioData.length
    const audioData = this.audioData.slice(startIdx, startIdx + length)
    this.sendedAudioData.push(...audioData)
    return audioData
  }

  // 向 iseWebSocket 发送数据
  webSocketSend() {
    this.closeEventCode += 'k'
    console.log('webSocketSend', this.iseWebSocket.readyState)
    if (this.iseWebSocket.readyState !== 1) {
      return
    }
    this.closeEventCode += 'l'
    const audioData = this.cropAudioData()
    const params = {
      common: {
        app_id: this.appId,
      },
      business: {
        category: 'read_chapter', // read_syllable/单字朗读，汉语专有 read_word/词语朗读  read_sentence/句子朗读 https://www.xfyun.cn/doc/Ise/IseAPI.html#%E6%8E%A5%E5%8F%A3%E8%B0%83%E7%94%A8%E6%B5%81%E7%A8%8B
        rstcd: 'utf8',
        group: 'adult',
        sub: 'ise',
        tte: 'utf-8',
        cmd: 'ssb',
        auf: 'audio/L16;rate=16000',
        ent: 'cn_vip',
        aus: 1,
        aue: 'raw',
        ...this.params,
      },
      data: {
        status: 0,
        encoding: 'raw',
        data_type: 1,
        data: buffer2Base64(audioData),
      },
    }
    if (!params.business.text?.startsWith('\uFEFF')) {
      params.business.text = `\uFEFF${params.business.text || ''}`
    }

    this.iseWebSocket.send(JSON.stringify(params))

    if (this.handlerInterval) {
      clearInterval(this.handlerInterval)
      this.handlerInterval = null
    }

    this.handlerInterval = setInterval(() => {
      // console.log('handlerIntervale')
      if (this.iseWebSocket.readyState !== 1) {
        this.closeEventCode += 'm'
        // websocket 未连接
        this.audioData = []
        this.sendedAudioData = []
        clearInterval(this.handlerInterval)
        this.setStatus(Recorder_status.RECORD_FINISH)
        return
      }
      // 最后一帧
      if (this.audioData.length === this.sendedAudioData.length) {
        this.closeEventCode += 'n'
        if (this.status === Recorder_status.RECORD_END) {
          this.closeEventCode += 'o'
          this.iseWebSocket.send(
            JSON.stringify({
              business: {
                cmd: 'auw',
                aus: 4,
                aue: 'raw',
              },
              data: {
                status: 2,
                encoding: 'raw',
                data_type: 1,
                data: '',
              },
            })
          )
          this.audioData = []
          this.sendedAudioData = []
          clearInterval(this.handlerInterval)
          this.setStatus(Recorder_status.RECORD_FINISH)
        }
        return false
      }
      const audioData = this.cropAudioData()
      // 中间帧
      this.iseWebSocket.send(
        JSON.stringify({
          business: {
            cmd: 'auw',
            aus: 2,
            aue: 'raw',
          },
          data: {
            status: 1,
            encoding: 'raw',
            data_type: 1,
            data: buffer2Base64(audioData),
          },
        })
      )
    }, 10)
  }
}

function sendErrorLogToServer(message) {
  this.$axios.get('course/front/permit/test/tongue/logs', {
    params: {
      message,
    },
  })
}

function buffer2Base64(buffer) {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

function transAudioData(audioData) {
  return transcode(audioData)

  function transcode(audioData) {
    let output = to16kHz(audioData)
    output = to16BitPCM(output)
    output = Array.from(new Uint8Array(output.buffer))
    return output
  }

  function to16kHz(audioData) {
    var data = new Float32Array(audioData)
    var fitCount = Math.round(data.length * (16000 / 44100))
    var newData = new Float32Array(fitCount)
    var springFactor = (data.length - 1) / (fitCount - 1)
    newData[0] = data[0]
    for (let i = 1; i < fitCount - 1; i++) {
      var tmp = i * springFactor
      var before = Math.floor(tmp).toFixed()
      var after = Math.ceil(tmp).toFixed()
      var atPoint = tmp - before
      newData[i] = data[before] + (data[after] - data[before]) * atPoint
    }
    newData[fitCount - 1] = data[data.length - 1]
    return newData
  }

  function to16BitPCM(input) {
    var dataLength = input.length * (16 / 8)
    var dataBuffer = new ArrayBuffer(dataLength)
    var dataView = new DataView(dataBuffer)
    var offset = 0
    for (var i = 0; i < input.length; i++, offset += 2) {
      var s = Math.max(-1, Math.min(1, input[i]))
      dataView.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true)
    }
    return dataView
  }
}
